.nav {


    -webkit-box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 1);
    box-shadow: 0px 1px 5px 0px rgba(54, 54, 54, 1);

}

.logo-align {
    display: block;
    width: 15%;
    height: auto;
    margin-left: 4em;
}

@media (max-width: 768px) {
    .nav {
        height: 8vh;
    }

    .logo-align {
        margin-left: 0px;
        margin: 0 auto;
        width: 52%;
        height: auto;

    }
}