@media (min-width: 768px) {
    .convo-box {
        border: 1px solid rgba(54, 54, 54, 1);
        border-radius: 10px;
    }
}


@media (max-width: 768px) {
    .card-pos {
        margin-top: -2em;
    }
}